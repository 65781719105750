<script lang="ts">
  import { queryStore } from '@svelte/service/backend/queryStore';
  import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
  import { AppEventName } from 'lib/events/contracts';
  import { AppEventTarget } from 'lib/events/globalEvents';
  import { onDestroy } from 'svelte';
  import { MarketingNotifierStorage } from './storage/types';
  import BrowserMarketingNotifierStorage from './storage/MarketingNotifierStorage';
  import { newsletterBannerSeen } from '@svelte/state/newsletterBanner';

  export let eventHandler: AppEventTarget;
  export let queryWorker: DisruptorServiceWorkerAsync;
  export let storage: MarketingNotifierStorage =
    new BrowserMarketingNotifierStorage(eventHandler, queryWorker);

  const discount = 20;
  const waitTime = 10_000;
  // Avoid dispatching event more than once on app re-render (Gatsby/React safety)
  let prompted = false;

  let timeout: NodeJS.Timeout;

  const userQuery = queryStore({
    key: 'session',
    variables: {},
    worker: queryWorker
  });
  $: currentUser = $userQuery.data?.currentUser;
  $: alreadySubscribed = currentUser?.subscribedToNewsletter;

  $: {
    if (currentUser) {
      clearTimeout(timeout);
      if (!$newsletterBannerSeen && !alreadySubscribed && !prompted) {
        timeout = setTimeout(async () => {
          if (currentUser) {
            prompted = true;
            storage
              .shouldShowNewsletterPrompt(currentUser.id)
              .then(shouldShow => {
                if (shouldShow && currentUser) {
                  eventHandler.dispatch(AppEventName.NewsletterOfferPrompted, {
                    discount
                  });
                }
              })
              .catch(() => {});
          }
        }, waitTime);
      }
    }
  }

  onDestroy(() => {
    clearTimeout(timeout);
  });
</script>
