<script lang="ts">
  import MarketingNotifier from '@svelte/modules/comms-menu/marketing-notifier/MarketingNotifier.svelte';
  import { appEventDispatcher } from 'lib/events/Provider';
  import { disruptorServiceWorkerRemote } from '@svelte/service/worker/loader';
  import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';

  const queryWorker =
    disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync;
</script>

<MarketingNotifier eventHandler={appEventDispatcher} {queryWorker} />
