export enum MAIN_NAVIGATION_ROUTER_KEY {
  ACCOUNT = 'account',
  ALL_PRODUCTS = 'all-products',
  BLOG = 'blog',
  CART = 'cart',
  CHECKOUT = 'checkout',
  CHECKOUT_ORDER_DETAILS = 'order-details',
  CONTACT_US = 'contact-us',
  ECOFESTO = 'ecofesto',
  HOME = 'home',
  INGREDIENTS = 'ingredients',
  LOGIN = 'login',
  ORDER_HISTORY = 'orders',
  OUR_STORY = 'our-story',
  POLICIES = 'policies',
  POLICY_TC = 'terms-and-conditions',
  POLICY_PRIVACY = 'privacy-policy',
  PRESS = 'press',
  PRODUCT = 'product'
}
